import request from '@/utils/request'


// 查询用户作品封面申请列表
export function listDirectImageApply(query) {
  return request({
    url: '/platform/direct-image-apply/list',
    method: 'get',
    params: query
  })
}

// 查询用户作品封面申请分页
export function pageDirectImageApply(query) {
  return request({
    url: '/platform/direct-image-apply/page',
    method: 'get',
    params: query
  })
}

// 查询用户作品封面申请详细
export function getDirectImageApply(data) {
  return request({
    url: '/platform/direct-image-apply/detail',
    method: 'get',
    params: data
  })
}

// 新增用户作品封面申请
export function addDirectImageApply(data) {
  return request({
    url: '/platform/direct-image-apply/add',
    method: 'post',
    data: data
  })
}

// 修改用户作品封面申请
export function updateDirectImageApply(data) {
  return request({
    url: '/platform/direct-image-apply/edit',
    method: 'post',
    data: data
  })
}

// 删除用户作品封面申请
export function delDirectImageApply(data) {
  return request({
    url: '/platform/direct-image-apply/delete',
    method: 'post',
    data: data
  })
}
